<template>
    <div class="bg" style="min-height: 100vh; display: flex; flex-direction: column; align-items: space-between;">
        <div :class="['darkenBg', {'active': card.show}]" @click.native="hideCard"></div>
        <CennikCard :prices="card.prices" :name="card.name" :show="card.show" @click.native="hideCard"></CennikCard>    
        <div style="width: 100%;">
            <Navbar></Navbar>
        </div>
        <div class="body">
            <div class="container has-text-centered">
                <h1 class="textTitle">Cennik</h1>
                <div class="cardContainer mt-4">
                    <div class="cardd" @click="showCard('Sprzątanie wnętrza', prices.wnetrze)">
                        <img src="/images/about/wnetrze.png" class="bgCard" />
                        <div style="z-index: 2;">
                            <span class="textHead">Sprzątanie wnętrza</span>
                            <br>
                            <span class="desc">Sprawdź ofertę</span>
                        </div>
                    </div>
                    <div class="cardd" @click="showCard('Czyszczenie Felg', prices.felgi)">
                        <img src="/images/about/felga.png" class="bgCard" />
                        <div style="z-index: 2;">
                            <span class="textHead">Czyszczenie Felg</span>
                            <br>
                            <span class="desc">Sprawdź ofertę</span>
                        </div>
                    </div>
                    <div class="cardd" @click="showCard('Mycie samochodu', prices.samochod)">
                        <img src="/images/about/polowaAuta.png" class="bgCard" />
                        <div style="z-index: 2;">
                            <span class="textHead">Mycie samochodu</span>
                            <br>
                            <span class="desc">Sprawdź ofertę</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: auto">
            <Footer></Footer>
        </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';
import CennikCard from '@/components/CennikCard.vue';
import { getEnv } from '@/utils.js';

export default {
  components: {
    Footer, Navbar, CennikCard
  }, data() {
      return {
          active: false,
          prices: {
              wnetrze: [],
              felgi: [],
              samochod: []
          }, card: {
              show: false,
              name: undefined,
              prices: []
          }
      }
  }, created() {
      console.log(process.env)
      this.prices.wnetrze = JSON.parse(this.getEnv('CENNIK_WNETRZE'));
      this.prices.felgi = JSON.parse(this.getEnv('CENNIK_FELGI'));
      this.prices.samochod = JSON.parse(this.getEnv('CENNIK_SAMOCHOD'));
  }, methods: {
      getEnv,
      showCard(name, prices) {
          this.card.name = name;
          this.card.prices = prices;
          this.card.show = true;
      }, hideCard() {
        //   if(this.card.show) {
              this.card.show = false;
        //   }
      }
  }
}
</script>
<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.cardContainer {
    display: flex;
    gap: 50px;
    // grid-template-columns: repeat(3, 1fr);
    // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    // grid-template-rows: 300px;
    // column-gap: 80px;
    // grid-gap: 50px;


    max-width: 1000px;
    margin: 0 auto;

    align-items: center;

    @include touch {
        flex-direction: column;
    }
}

.cardd {
    cursor: pointer;

    width: 300px;
    height: 300px;

    border-radius: 32px;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    position: relative;

    .textHead {
        color: var(--primary);
        font-size: 1.3rem;
        font-weight: 800;
    }

    .desc {
        font-size: 1.1rem;
        font-weight: 500;
        color: #fff;
    }
}


.bgCard {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 24px;
    filter: brightness(55%);
}

.darkenBg {
    position: fixed; 
    
    top:0; 
    left:0; 
    bottom:0; 
    right:0; 
    
    backdrop-filter: brightness(60%) opacity(0);
    z-index: -5;

    transition: backdrop-filter .5s, z-index .5s step-end;

    &.active {
        z-index: 4;
        backdrop-filter: brightness(60%) opacity(1);

        transition: backdrop-filter .5s, z-index .5s step-start;
    }
}
</style>