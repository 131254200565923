<template>
    <div :class="['fixed', {'active': show}]">
        <div :class="['fullCard', 'has-text-centered', {'show': show}]">
            <span class="cardTitle">{{ name }}</span>
            <div class="priceContainer">
                <div class="small" v-for="obj in prices" :key="obj">
                    <span :class="['smallTitle', {'big': obj.highlight}]">{{ obj.name }}</span>
                    <br>
                    <span :class="['smallDesc', {'big': obj.highlight}]">{{ obj.price }}</span>
                </div>
            </div>
            <div class="buttonsContainer">
                <a class="btn order">
                    <font-awesome-icon icon="fa-solid fa-paper-plane" class="ic"></font-awesome-icon>
                    <span class="txt">Zamów usługę</span>
                </a>
                <a class="btn rules">
                    <font-awesome-icon icon="fa-solid fa-file" class="ic"></font-awesome-icon>
                    <span class="txt">Regulamin</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        prices: Array,
        name: String,
        show: Boolean
    }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.fixed {
    position: fixed; 
    
    top:0; 
    left:0; 
    bottom:0; 
    right:0; 
    
    display: flex; 
    align-items: center; 
    justify-content: center; 
    
    z-index: -5;

    &.active {
        z-index: 5;
    }
}
.fullCard {
    padding: 30px;
    background-color: #171717;
    border: 0.44px solid #ffb82c;
    border-radius: 40px;
    width: 600px;

    opacity: 0;
    display: hidden;

    transition: opacity .5s;

    &.show {
        display: block;
        opacity: 1;
    }

    @include touch {
        width: 450px;
    }

    @include until(500px) {
        width: 90%;
    }
}

.cardTitle {
    font-weight: 800;
    color: var(--primary);
    font-size: 1.75rem;

    @include touch {
        font-size: 1.5rem;
    }
}

// .small {
//     margin: 1.5rem 0;
// }

.smallTitle {
    font-weight: 800;
    color: var(--primary);
    font-size: 1.3rem;
    line-height: 35px;

    @include touch {
        font-size: 1.2rem;
    }

    &.big {
        font-size: 1.75rem;

        @include touch {
            font-size: 1.5rem;
        }
    }
}

.smallDesc {
    padding-top: .25rem;
    display: inline-block;
    font-weight: 800;
    color: #fff;
    font-size: 1.6rem;
    line-height: 36px;

    @include touch {
        font-size: 1.4rem;
    }

    &.big {
        font-size: 2.3rem;

        @include touch {
            font-size: 1.75rem;
        }
    }
}

.rules {
    text-decoration: underline;
    color: var(--primary);
    font-weight: 500;
    font-size: 0.9rem;
}

.errorText {
    color: var(--primary)
}

.priceContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin: 1.5rem 0;

    @include until(500px) {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
}

.btn {
    padding: 8px 24px;
    border-radius: 24px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    &.order {
        color: #000;    
        background-color: var(--primary);
    }

    &.rules {
        color: #fff;    
        background-color: #4E4E4E;
    }

    .ic {
        font-size: 1.75rem;
    }

    .txt {
        font-size: 1.25rem;

        @include touch {
            font-size: 1.1rem;
        }
    }
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    flex-wrap: wrap;
}
</style>